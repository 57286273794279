import { SET_COMPARE_VEHICLE, SET_COMPARE_VEHICLE_ID, SET_COMPARE_VEHICLE_SPECIFICATION } from './types';

export const setCompareVehicle = (comparedVehicles) => (dispatch) => {
  
  try {
    return dispatch({ type: SET_COMPARE_VEHICLE, payload: comparedVehicles });
  } catch (error) {
    return error;
  }
};
export const setCompareVehicleSpecification = (specifications) =>(dispatch)=>{
  try{

    return dispatch({type:SET_COMPARE_VEHICLE_SPECIFICATION, payload: specifications})
  }catch(error){
    return error;
  }
}
export const setCompareVehicleId = (comparedVehiclesIds) => (dispatch) => {
  try {
    return dispatch({
      type: SET_COMPARE_VEHICLE_ID,
      payload: comparedVehiclesIds,
    });
  } catch (error) {
    return error;
  }
};
